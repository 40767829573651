(function(){
  'use strict';
  angular
  .module('app')
  .component('auth', {
    templateUrl: 'app/components/auth/auth.html',
    controller: AuthController
  });

  AuthController.$inject = ['$window', '$state','user', 'authService'];

  function AuthController($window, $state, $user, $authService) {
    var vm = this;
    vm.send = false;
    vm.doLogin = doLogin;


    function doLogin() {
      vm.send = true;
      $authService.doLogin(vm.login)
      .then(function(res){
        vm.send = false;
        //console.log('Inicio de session exitoso')
        var user  = res.user;
        user.technical = false;
        $user.setCurrentUser(user);
        $window.location = '/home'
      }, function(err){
        vm.send = false;
        //console.log(err.data.message);
        vm.login = {};
      })
    }
  }
})();
