(function(){
  'use strict';

  angular
  .module('app')
  .component('createAccounts', {
    templateUrl: 'app/components/accounts-movil-pay/admin-account.html',
    controller: ModalCreateCounpon,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalCreateCounpon.$inject = ['toastr','accountService','_','$rootScope','user','globalService'];

  function ModalCreateCounpon(toastr, $accountService, _, $rootScope,$user, $globalService) {
    var vm = this;
    vm.save = save;
    vm.send = false;
    vm.cancel = cancel;
    vm.user = $user.getCurrentUser();
    vm.typeBanco = [{tipo:'Corriente', key: 'corriente'},{tipo: 'Ahorro', key: 'ahorro'},{tipo: 'Pago Movil', key: 'pago_movil'}];

    vm.$onInit = function(){
      $globalService.getBancos()
      .then(function(res){
        vm.bancos = res;
      })
      if(vm.resolve.account){
        vm.account = vm.resolve.account;
        vm.tipoSelected = _.findWhere(vm.typeBanco, {tipo: vm.account.tipo_cuenta});
        vm.accountSelected = vm.account.banco
      }
    }

    function save() {
      vm.send = true;
      // vm.account.active = eval(vm.account.active);
      if(vm.account.id){
        $accountService.updatedddAccount(vm.account.id, vm.account)
        .then(function(res){
          vm.send = false;
          toastr.success(res.message);
          $rootScope.$emit('accounts',{});
          vm.close();
        }, function(err){
          vm.send = false;
          toastr.error(err.data.message);
        })
      }else{
        $accountService.createdddAccount(vm.account)
        .then(function(res){
          vm.send = false;
          toastr.success(res.message);
          $rootScope.$emit('accounts',{});
          vm.close();
        }, function(err){
          vm.send = false;
          toastr.error(err.data.message);
        })
      }
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
