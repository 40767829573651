(function(){
  'use strict';

  angular
  .module('app')
  .filter('prettyAmount', prettyAmount)

  function prettyAmount(accounting){
    return function(amount){
      return accounting.formatMoney(amount, 'Bs. ','2','.',',');
    }
  }

})();
