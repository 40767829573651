(function(){
  'use strict';
  angular
  .module('app')
  .component('defaultRequest', {
    templateUrl: 'app/components/default-request/default-request.html',
    controller: DefaultRequestController
  });

  DefaultRequestController.$inject = ['globalService','toastr']

  function DefaultRequestController($globalService, toastr) {
    var vm = this;
    vm.save = save;


    vm.$onInit = function(){
      $globalService.getDefaultRequest()
      .then(function(res){
        vm.setting = res;
      })

    }


    function save(){
      swal({
        title: 'Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.saveDefaultRequest(vm.setting)
            .then(function(res){
              resolve();
              toastr.info(res.message);
            }, function(err){
              reject();
              toastr.error(err.data.message);
            })
          });
        },
        allowOutsideClick: false,
      })
    }

  }

})();
