(function () {
  'use strict';

  angular
    .module('app')
    .component('modalCajeroCustomers', {
      templateUrl: 'app/components/manage-customers/modal-cajero.html',
      controller: ModalCajeroController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    })

  ModalCajeroController.$inject = ['toastr', 'globalService', '_', 'user', '$uibModal','$sce','$window'];

  function ModalCajeroController(toastr, $globalService, _, $user, $uibModal, $sce, $window) {
    var vm = this;
    vm.loading = true;
    vm.interval = "today";
    vm.disabledInput = true;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function () {
      console.log(vm.resolve.estructura)
      vm.estructura = vm.resolve.estructura;
      console.log(vm.estructura)
      $globalService.getLastOperaciones({ estructura_id: vm.estructura.id, interval: vm.interval })
        .then(function (res) {
          vm.loading = false;
          vm.transactions = res;
        })
    }

    vm.enableInput = function(){
      console.log(vm.disabledInput)
    }

    vm.showMore = function () {
      $globalService.getLastOperaciones({ estructura_id: vm.estructura.id, interval: moment(vm.from).format('YYYY-MM-DD') })
        .then(function (res) {
          vm.loading = false;
          vm.transactions = res;
        })
    }

    vm.getCajeroUpdated = function () {
      $globalService.getCajeroWeb(vm.estructura.cajero_web.id)
        .then(function (res) {
          vm.estructura.cajero_web = res;
        })
    }

    vm.save = function (){
      $globalService.updateCajeroWeb(vm.estructura.cajero_web.id, vm.estructura.cajero_web)
      .then(function (res) {
        vm.estructura.cajero_web = res.cajero_web;
        swal('Cajero Actualizado', res.message, 'success')
      }, function(err){
        swal('Error', err.data.message, 'error');
      })
    }

    vm.reverse = function (data) {
      var modal = $uibModal.open({
        animation: true,
        component: 'codeComponent',
        windowClass: 'createLoteriasComponent',
        backdrop: false,
        size: 'xs',
      });

      modal.result.then(function (res) {
        doReverse(data)
      }, function (err) {
        toastr.error('Accion Cancelada');
      });
    }

    function doReverse(data) {
      var data_send = {
        cajero_web_id: data.cajero_web_id,
        cajero_web_operacion_id: data.id

      }
      Swal({
        title: 'Esta seguro de reversar la operacion?',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function (reason) {
          return $globalService.reverseOperation(data_send)
            .then(function (res) {
              vm.getCajeroUpdated();
              vm.showMore();
              toastr.success('Transaccion Reversada');
            }, function (err) {
              swal.showValidationError(err.data.message)
            })
        }
      })
    }

    vm.showTicket = function(transaction, ticket_print,ticket_ws){
      var modal = $uibModal.open({
        animation: true,
        component: 'gModalTicket',
        backdrop: 'static',
        windowClass: 'modalTicket',
        size: 'xs',
        resolve: {
          ticket: function() {
            return vm.ticket = $sce.trustAsHtml(angular.copy(transaction.detalle.replace(/\n|\r|\\n/g, '<br>')));
          },
          ticket_print: function(){
            return ticket_print;
          },
          ticket_ws: function(){
            return ticket_ws;
          }
        },
      });
    }

    vm.today = function() {
      vm.from = new Date();
      vm.to = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      // maxDate: new Date(2020, 5, 22),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup2.opened = true;
    };
    vm.open1 = function(){
      vm.popup2.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[4];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }


  }
})();
