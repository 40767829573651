(function(){
  'use strict';

  angular
  .module('app')
  .component('modalEffecty', {
    templateUrl: 'app/components/modals/modal-effecty.html',
    controller: ModalEffectyController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalEffectyController.$inject = ['toastr','_','$rootScope','user','cashierService','$window','$uibModal','globalService'];

  function ModalEffectyController(toastr, _, $rootScope,$user, $cashierService, $window, $uibModal, $globalService) {
    var vm = this;
    vm.save = save;
    vm.send = false;

    vm.$onInit = function(){
      $cashierService.getAllEffecty()
      .then(function(res){
        vm.requests = res;
      })
    }

    function save() {

    }

    vm.code = function(data){
      vm.currentRequest = data;
      var modal = $uibModal.open({
        animation: true,
        component: 'codeComponent',
        windowClass: 'createLoteriasComponent',
        backdrop: false,
        size: 'xs',
      });

      modal.result.then(function(res){
        doUpdateRequest()
      }, function(err){
        toastr.error('Accion Cancelada');
      });
    }

    function doUpdateRequest(data){
      $cashierService.updateCashierEffecty(vm.currentRequest)
      .then(function(res){
        swal('Info',res.message,'info');
        vm.close();
      })
    }
  }
})();
